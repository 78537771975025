<script setup lang="ts">
import {
  useRoute, useRouter
}                            from "vue-router";
import {
  ChartBarIcon, CogIcon, QuestionMarkCircleIcon
}                            from "@heroicons/vue/outline";

import { SETTINGS_ROUTES }   from "@/modules/settings/utils/constants/routes"; 
import ArrowRightRectIcon    from "@/modules/shared/components/Icons/ArrowRightRectIcon.vue";
import useUserInfo           from "@/modules/shared/composable/useUserInfo";
import imgUrl                from "@/assets/images/logo_white.svg";

const route                    = useRoute();
const router                   = useRouter();
const { userInfo, isLoading }  = useUserInfo();

const navigation    = [
  { router: true,  name: "Dashboard",  icon: ChartBarIcon,           href: "dashboard",                        current: route.name === "dashboard" ? true : false },
  { router: true,  name: "Settings",   icon: CogIcon,                href: SETTINGS_ROUTES.MailboxesSettings,  current: route.matched[0].name === "settings" ? true : false },
  { router: false, name: "Help",       icon: QuestionMarkCircleIcon, href: "https://help.emailmeter.com/en/",  current: false },
];

const logout = () => {
  router.push({ name: "login" });
  localStorage.removeItem("userToken");
};
</script>

<template>
  <div v-if="!isLoading" class="flex w-screen h-screen">
    <div class="h-screen flex w-72 min-h-0 flex-col bg-secondary-500">
      <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
        <div class="flex flex-shrink-0 items-center px-6.5">
          <router-link :to="{ name: 'dashboard'}">
            <img :src="imgUrl" alt="Email Meter" width="150" />
          </router-link>
        </div>
        <nav class="mt-10.5 flex-1 space-y-1 px-4" aria-label="Sidebar">
          <template v-for="item in navigation" :key="item.name">
            <router-link v-if="item.router" :to="{ name: item.href}" :href="item.href" :class="[item.current ? 'bg-secondary-400 text-white' : 'text-gray-300 transition duration-0 hover:duration-200 hover:text-white', 'group flex items-center p-2.75 text-sm font-medium rounded-md']">
              <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              <span class="flex-1">{{ item.name }}</span>
            </router-link>
            <a v-else :href="item.href" target="_blank" class="text-gray-300  hover:text-white group flex items-center p-2.75 text-sm font-medium rounded-md">
              <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              <span class="flex-1">{{ item.name }}</span>
            </a>
          </template>
        </nav>
      </div>

      <div class="flex flex-shrink-0 justify-between p-4">
        <div class="relative w-full px-2.5">
          <p class="my-3 uppercase font-semibold text-xs text-secondary-300">ACCOUNT</p>
          <div class="w-full flex items-center justify-between">
            <div>
              <p class="my-0 text-sm font-medium leading-5 text-gray-200">{{ userInfo!.name }}</p>
              <p class="my-0 text-xs text-white leading-4 whitespace-nowrap truncate w-56">{{ userInfo!.email }}</p>
            </div>
            <div class="ml-2 cursor-pointer w-5 h-5">
              <ArrowRightRectIcon class="text-gray-200 w-5 h-5" @click="logout" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="header"></slot>
    <slot name="dashboard"></slot>
  </div>
</template>

<style scoped></style>