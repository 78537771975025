<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import PageTitleSettings  from "@/modules/settings/components/PageTitleSettings.vue";
import TeamsTable         from "@/modules/settings/components/table/taps/TeamsTable.vue";
import useTable           from "@/modules/settings/composables/useTable";
import useTeams           from "@/modules/settings/composables/useTeams";
import useMailboxes       from "@/modules/settings/composables/useMailboxes";

const {
  getTeamsLength, teams, isLoadingGet, isErrorGet, removeModal, showModal, selectedItems, indeterminate, selectAll, selectItem
}                 = useTeams();
const { filterInputVal, search, data, order, asc, typeOrder
}                 = useTable(teams, "department");
const queryClient = useQueryClient();
useMailboxes();

const remove = (e:any) => { removeModal(e, queryClient); };
</script>

<template>
  <PageTitleSettings
    title="Teams"
    description="Group your mailboxes for easier analysis"
    button="Create new team"
    :search="search"
    placeholder="Search teams"
    @showModal="showModal"
    @changeSearch="filterInputVal"
  />
  <TeamsTable
    :data="data"
    :search="search"
    :asc="asc"
    :isLoading="isLoadingGet"
    :isErrorGet="isErrorGet"
    :dataLength="getTeamsLength"
    :typeOrder="typeOrder"

    :selectedItems="selectedItems"
    :indeterminate="indeterminate"
    @selectAll="selectAll"
    @selectItem="selectItem"

    @orderBy="order"
    @showModal="showModal"
    @removeModal="remove"
    @clearSearch="() => { filterInputVal('') }"
  />
</template>

<style scoped></style>