<script setup lang="ts">
import { toRefs } from "vue";
import { SearchIcon } from "@heroicons/vue/solid";

interface Emits {
  (e: "showModal", data:any):void;
  (e: "changeSearch", value:string):void;
}
const emits = defineEmits<Emits>();
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  dataLength: {
    type: Number,
    required: false,
  },
  max: {
    type: Number,
    required: false,
  },
  button: {
    type: String,
    required: true,
  },
  search: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: "Search by name or email",
  }
});

const { title, description, dataLength, max} = toRefs(props);

const onChangeSearch = (e:any)=> { emits("changeSearch", e.target.value); }; 
</script>

<template>
  <div class="mt-6 mb-9">
    <div class="flex">
      <div class="flex-1">
        <div class="flex items-center mb-1">
          <h3 class="mt-0 text-gray-900 font-medium font-inter text-lg mb-0 mr-2">{{ title }}</h3>
          <div v-if="dataLength && max" class="self-center text-left">
            <p class="inline-flex items-center rounded-2xl bg-indigo-100 text-xs px-2 p-1 m-0 font-medium text-indigo-600">
              {{ `${dataLength} of ${max} used` }}
            </p>
          </div>
        </div>
        <p class="max-w-xl m-0 text-sm text-gray-500">{{ description }}</p>
      </div>
    </div>
  </div>
  <form class="flex items-center justify-between">   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-1/3 drop-shadow-base">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <SearchIcon class="w-5 h-5 text-gray-400" />
      </div>
      <input
        type="text"
        :value="search"
        @input="onChangeSearch($event)"
        id="search-email"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
        :placeholder="placeholder"
        required
      >
    </div>
    <button
      type="button"
      class="drop-shadow-base py-2 px-4 cursor-pointer border border-transparent bg-indigo-500 text-sm font-medium rounded-md text-white focus:outline-none hover:bg-indigo-600"
      @click="emits('showModal', {});"
    >
      {{ button }}
    </button>
  </form>
</template>

<style scoped></style>
