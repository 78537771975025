export const numberToWords = (value: number): string => {
  value = Math.floor(value);
  const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  const numString = value.toString();

  if (value < 0) throw new Error("Negative numbers are not supported.");
  if (value === 0) return "zero";
  if (value < 20) return ones[value];
  if (numString.length === 2) return `${tens[Number(numString[0])]} ${ones[Number(numString[1])]}`;
  if (numString.length == 3) {
    if (numString[1] === "0" && numString[2] === "0")
      `${ones[Number(numString[0])]}  hundred`;
    else
      return `${ones[Number(numString[0])]} hundred and ${numberToWords(+(numString[1] + numString[2]))}`;
  }
  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return `${ones[Number(numString[0])]} thousand`;
    if (end < 100)
      return `${ones[Number(numString[0])]} thousand and ${numberToWords(end)}`;
    return `${ones[Number(numString[0])]} thousand ${numberToWords(end)}`;
  }
  return "";
};