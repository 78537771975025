import { storeToRefs } from "pinia";

import { useModalStore } from "@/modules/shared/stores/modal";

const useRemoveModal = () => {
  const store = useModalStore();
  const { dataModal } = storeToRefs( store );

  return {
    // variable
    dataModal,

    // Methods
    // composable
  };
};

export default useRemoveModal;
