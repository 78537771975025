<script setup lang="ts">
import type { PropType }                          from "vue";
import ButtonR                                    from "@/modules/shared/components/ButtonRemove.vue";
import ButtonG                                    from "@/modules/shared/components/ButtonComponent.vue";
import { Menu, MenuButton, MenuItem, MenuItems }  from "@headlessui/vue";
import {
  DotsHorizontalIcon, CheckIcon
}                                                 from "@heroicons/vue/solid";
import type { IUsers }                            from "@/modules/settings/interfaces/Users";
import useMailboxes                               from "@/modules/settings/composables/useMailboxes";
import useUserInfo                                from "@/modules/shared/composable/useUserInfo";

interface Emits {
  (e: "showModal", data:any ):void;
  (e: "removeModal", mailboxes:string[]):void;
  (e: "selectItem", event:any, email:string):void;
}
const emits = defineEmits<Emits>();

const props = defineProps({
  data: {
    type: Object as PropType<IUsers[]>,
    required: true,
  },
  selectedItems:{
    type: Array as PropType<string[]>,
    required: true,
  },
});

// statements
const { userInfo }  = useUserInfo();
const { mailboxes } = useMailboxes();


const isTraking = (t:string) => mailboxes.value.filter((p:any) => (p.email === t)).length > 0;
const dataFrom = (role:string, data:string[]) => {
  return role === "Admin" ? "All mailboxes" : data.length == 0 ? "Own mailbox" : ` ${data.length} mailboxes`;
};

const isDisabled = (email:string) => userInfo.value?.email === email;

</script>

<template>
  <tbody class="divide-y divide-gray-200 ">
    <tr v-for="user in data" :key="user.email" :class="[selectedItems.includes(user.email) && 'bg-gray-50']" class="h-18">
      <td class="relative px-7 sm:w-12 sm:px-6">
        <input
          v-if="!isDisabled(user.email)"
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="props.selectedItems.includes(user.email)"
          @change="emits('selectItem', $event, user.email)"
        />
      </td>
      <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-700">
        {{ user.email }}
        <br >
        <p class="text-gray-500 font-normal">{{ user.name }}</p>
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
        {{ user.role }}
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
        {{ dataFrom(user.role, user.dataFrom) }}
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
        <CheckIcon v-if="isTraking(user.email)" class="h-4 w-4 text-green-500" aria-hidden="true" />
      </td>
      <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3">
        <Menu v-if="!isDisabled(user.email)" as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="flex items-center rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
              <span class="sr-only">Open options</span>
              <DotsHorizontalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-50 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <MenuItem>
                  <ButtonR class="!text-left" :inactive="isDisabled(user.email)" layout="ButtonRemove" type-color="gray" text="Edit role" @click="emits('showModal', { email: user.email ,role: user.role, mailboxes: user.dataFrom })" />
                </MenuItem>
                <MenuItem>
                  <ButtonR :inactive="isDisabled(user.email)" type-color="red" text="Remove user" @click="emits('removeModal', [user.email])" />
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<style scoped></style>
