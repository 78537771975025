import { ref } from "vue";
import { defineStore } from "pinia";
import type { IUserInfo } from "@/modules/shared/interfaces/UserInfo";


export const useUserInfoStore = defineStore("userInfo", () => {

  const userInfo = ref<IUserInfo | null>(null);

  return {
    // properties
    userInfo,

    // Actions
    setMailboxes( newUserInfo: IUserInfo ) { userInfo.value = newUserInfo; },
  };
});
