import type { IMailboxes, MailboxesInitial }  from "@/modules/settings/interfaces/Mailboxes";
import type { IUsers, IUsersInitial }         from "@/modules/settings/interfaces/Users";
import type { IRolesMapping }                 from "@/modules/settings/interfaces/Users";


export default class SettingsParser {
  static parseMailboxes(data: MailboxesInitial[]) {
    const parsed: IMailboxes[] = [];

    const status = {
      "sync_error": "Error",
      "loading_data": "Loading data",
      "working": "Active"
    };

    data.forEach((mailbox: MailboxesInitial) => {
      parsed.push({
        "email": mailbox.email,
        "dataAdded": mailbox.created,
        "dataFrom": mailbox.data_from,
        "businessHours": mailbox.business_hours.length ? mailbox.business_hours : null,
        "status": status[mailbox.status],
        "teams": mailbox.teams,
        "name": mailbox.name,
      });
    });
    return parsed;
  }

  static parseUsers(data: IUsersInitial[]) {
    const parsed: IUsers[] = [];

    const ROLES_MAPPING: IRolesMapping = {
      "enterprise-portal-admin": "Admin",
      "enterprise-portal-manager": "Manager",
      "enterprise-portal-user": "Viewer",
    };

    data.forEach((user: IUsersInitial) => {
      parsed.push({
        "email": user.email,
        "isAdmin": user.is_admin,
        "role": ROLES_MAPPING[user.role as keyof IRolesMapping],
        "dataFrom": user.can_see_data_for,
        "lastActive": user.last_active,
        "tracking": user.tracked,
        "name": user.name
      });
    });

    return parsed;
  }
}
