<script setup lang="ts">
import MailboxesModal from "@/modules/settings/components/modals/MailboxesModal.vue";
import TeamModal from "@/modules/settings/components/modals/TeamModal.vue";
import UserModal from "@/modules/settings/components/modals/UserModal.vue";
import RemoveModal from "@/modules/shared/components/RemoveModal.vue";

import MODALS_ENUM from "@/modules/shared/utils/modals_enum";

import { useModalStore } from "@/modules/shared/stores/modal";
import { XIcon } from "@heroicons/vue/solid";
import { storeToRefs } from "pinia";
import { onBeforeMount, onBeforeUnmount } from "vue";

const store = useModalStore();
const { display, hasCloseButton, activeModal } = storeToRefs( store );

const onKeyPress = (e:any) => {
  if (e.key === "Escape") {
    store.hideModal();
  }
};

onBeforeMount(() => {
  window.addEventListener("keydown", onKeyPress);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", onKeyPress);
});
</script>

<template>
  <Transition :duration="550" name="nested">
    <div v-if="display" class="z-50 is-active h-full w-full flex absolute overflow-hidden">
      <div class="bg-[rgba(0,0,0,0.5)] h-screen w-full absolute"></div>
      <div
        class="inner modal-content bg-white my-auto shadow-lg mx-auto lg:mx-auto sm:w-11/12 lg:w-3/4 xl:w-1/2 2xl:w-1/3 max-w-[580px] p-0 z-10 rounded-lg"
      >
        <div v-if="activeModal != MODALS_ENUM.removeModal">
          <button 
            v-if="hasCloseButton"
            class="modal-close float-right xl:mx-6 mx-5 xl:my-7 my-5 bg-transparent border-none text-xl text-gray-700"
            aria-label="close"
          >
            <XIcon class="w-6 h-6 text-gray-700" @click="store.hideModal"/>
          </button>
        </div>

        <MailboxesModal v-if="activeModal === MODALS_ENUM.addMailBoxes" />
        <UserModal v-if="activeModal === MODALS_ENUM.addUser" />
        <TeamModal v-if="activeModal === MODALS_ENUM.addTeams" />
        <RemoveModal v-if="activeModal === MODALS_ENUM.removeModal" />

      </div>
    </div>
  </Transition>
</template>

<style scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}

/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(0px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
</style>
