import { computed, ref, watch }  from "vue";
import { storeToRefs }           from "pinia";
import { useMutation, useQuery } from "@tanstack/vue-query";

import clientsApi         from "@/api/clients-api";
import { numberToWords }  from "@/modules/settings/utils/helpers/numbers";
import SettingsParser     from "@/modules/settings/utils/parser/settings";
import type { IUsers }    from "@/modules/settings/interfaces/Users";
import { useUsersStore }  from "@/modules/settings/stores/users";
import useInputMultiple   from "@/modules/shared/composable/useInputMultiple";
import useUserInfo        from "@/modules/shared/composable/useUserInfo";
import { useModalStore }  from "@/modules/shared/stores/modal";
import MODALS             from "@/modules/shared/utils/modals_enum";
import { useToast }       from "vue-toastification";


const getUsers = async():Promise<IUsers[]> => {
  const { data } = await clientsApi.get("/enterprise/settings/users/");
  return SettingsParser.parseUsers(data.results);
};

const createMailboxes = async( data: any):Promise<any> => {
  const { data:dataResp } = await clientsApi.post<any>("/enterprise/settings/users/", data.users );
  const query = data.queryClient.getQueryCache().find(["users"],{ exact: true });
  query.fetch();
  return dataResp;
};

const deleteUsers = async( data: any ):Promise<any> => {
  const { data:dataResp } = await clientsApi.delete<any>("/enterprise/settings/users/", { data: JSON.stringify({emails: data.data})  } );
  const query = data.queryClient.getQueryCache().find(["users"],{ exact: true });
  query.fetch();
  return dataResp;
};

const useUsers = () => {
  const { 
    email:emailInputMultiple, emails
  }                   = useInputMultiple();
  const store         = useUsersStore();
  const storeModal    = useModalStore();
  const { userInfo }  = useUserInfo();

  const { users }     = storeToRefs( store );
  const { dataModal } = storeToRefs( storeModal );
  const toast         = useToast();


  const getUseUsers = useQuery(
    [ "users"],
    getUsers
  );

  watch( getUseUsers.data, users => {
    if( users ) store.setUsers(users);
  }, { immediate: true });

  const usersCreateMutation = useMutation( createMailboxes );
  watch( usersCreateMutation.isSuccess, (isSuccess) => {
    if(isSuccess){
      storeModal.hideModal();
      if(usersCreateMutation.variables.value.type)
        toast.success("Role updated");
      else {
        const m = emails.value.length;
        toast.success(`${numberToWords(m)} new user${ m > 1 ? "s" : "" } added!`);
      }
      emails.value = [];
    }
  });

  const usersDeleteMutation = useMutation( deleteUsers );
  watch( usersDeleteMutation.isSuccess, (isSuccess) => {
    if(isSuccess) {
      storeModal.hideModal();
      const n = usersDeleteMutation.variables.value.data.length;
      toast.success(`${numberToWords(n)} user${n > 1 ? "s" : ""} removed`);
    }
  });

  // check Table
  const selectedItems = ref<string[]>([]);
  const indeterminate = computed(() => selectedItems.value.length > 0 && selectedItems.value.length < users.value.length);
  const selectAll = (e:any) => {
    selectedItems.value = e.target.checked ? users.value.filter((p:any) => { if (p.email !== userInfo.value!.email) return p.email; }).map(p => p.email) : [];
  };
  const selectItem = (e:any, email:string) => {
    e.target.checked 
      ? selectedItems.value = [email, ...selectedItems.value] 
      : selectedItems.value = selectedItems.value.filter((item) => item !== email);
  };
  const unselectAll = () => {
    selectedItems.value = [];
  };
    // check Table

  return {
    users,
    selectedItems,
    dataModal,
    // Method
    createUsers: usersCreateMutation,
    deleteUsers: usersDeleteMutation,
    selectAll,
    selectItem,
    removeModal: (users:string[], useQueryClient:any) =>{
      const title = `Are you sure you want to remove ${ users.length > 1 ? "these users" : "this user" }?`;
      const text = `${ users.join(", ") } will lose access to see email statistics.`;

      storeModal.showModal({
        name: MODALS.removeModal,
        data: {
          type: "users",
          title,
          text,
          data: users,
          useQueryClient,
          deleteEmails: users
        }
      });
      unselectAll();
    },
    showModal: (data:any) =>{
      emailInputMultiple.value = "";
      emails.value = [];
      storeModal.showModal({ name: MODALS.addUser, data });
    },
    // computed
    // get users
    getData:      computed(()=> getUseUsers.data ),
    isLoadingGet: computed(()=> getUseUsers.isLoading.value ),    
    isErrorGet:   computed(()=> getUseUsers.isError.value ),
    
    onlyExistEmails:    computed(()=> [...users.value.map(m => m.email)]),
    getUsersLength: computed(()=> users.value.length),
    indeterminate,
  };
};

export default useUsers;
