import { createRouter, createWebHistory } from "vue-router";
import settingsRoutes from "@/modules/settings/settings_routes";

import LoginComponent from "@/modules/shared/pages/LoginPage.vue";
import HomePage from "@/modules/shared/pages/HomePage.vue";


const routes = [
  {
    path: "/",
    name: "dashboard",
    component: HomePage
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent,
    props: true,
  }
];

routes.push(...settingsRoutes);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
