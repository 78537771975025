import { ref } from "vue";
import { defineStore } from "pinia";
import type { IMailboxes } from "@/modules/settings/interfaces/Mailboxes";


export const useMailboxesStore = defineStore("mailboxes", () => {

  const mailboxes = ref<IMailboxes[]>([]);

  return {
    // properties
    mailboxes,

    // Actions
    setMailboxes( newMailboxes: IMailboxes[] ) { mailboxes.value = newMailboxes; },
  };
});
