import { computed, watch }        from "vue";
import { storeToRefs }            from "pinia";
import { useQuery }               from "@tanstack/vue-query";
import clientsApi                 from "@/api/clients-api";

import { useDashboardStore }      from "@/modules/dashboard/stores/dashboard";



const getDashboard = async():Promise<any> => {
  const { data } = await clientsApi.get("/enterprise/dashboards");
  if(!data.count) return data.results; 
  else return data.results;
};

const useDashboard = () => {
  const storeDasboard  = useDashboardStore();
  const { dashboard }   = storeToRefs( storeDasboard );

  const getUseDashboard = useQuery( ["dashboard"], getDashboard );
  watch( getUseDashboard.data, dashboard => {
    if( dashboard ) storeDasboard.setDashboard(dashboard);
  }, { immediate: true });

  const href = (e:any):void => {
    if (dashboard.value.length)
      window.open(
        dashboard.value[0].url,
        "_blank"
      );
    else
      e.preventDefault();
  };

  return {
    // properties
    dashboard,

    // Method
    href,
    // computed
    getData:            computed(()=> getUseDashboard.data ),
    isLoadingGet:       computed(()=> getUseDashboard.isLoading.value ),
    isErrorGet:         computed(()=> getUseDashboard.isError.value ),
  };
};

export default useDashboard;
