import { VueQueryPlugin } from "@tanstack/vue-query";
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import Toast from "vue-toastification";
import vSelect from "vue-select";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "./assets/main.css";
import "vue-select/dist/vue-select.css";


if (import.meta.env.VITE_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_KEY as string,
    plugins: [new BugsnagPluginVue()],
  });
}
const bugsnagVue = Bugsnag.getPlugin("vue");

const app = createApp(App);

app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  position: "bottom-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.45,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

app.component("v-select", vSelect);
app.use(createPinia());
app.use(router);
app.use(bugsnagVue);

VueQueryPlugin.install( app, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        cacheTime: 0,
      }
    }
  }
});

app.mount("#app");
