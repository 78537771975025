<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  inactive: {
    type: Boolean,
    default: false,
  },
  typeColor: {
    type: String,
    required: false,
    default: "blue",
  },
});

const Color = computed(() => {
  if (props.typeColor == "red")        return props.inactive ? "!cursor-not-allowed !bg-red-300 !text-white"    : "bg-white text-red-500";
  else if (props.typeColor == "gray")  return props.inactive ? "!cursor-not-allowed text-gray-700"              : "bg-white text-gray-700";
  return props.inactive                                      ? "!cursor-not-allowed !bg-indigo-200 !text-white" : "bg-indigo-500 text-white hover:bg-indigo-600";
});
</script>

<template>
  <button
    type="button"
    class="block px-4 py-3 font-normal text-sm w-full text-left hover:bg-gray-100"
    :class="Color"
  >
    {{ text }}
  </button>
</template>
