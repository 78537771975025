import { computed, ref, watch }   from "vue";
import { storeToRefs }            from "pinia";
import { useMutation, useQuery }  from "@tanstack/vue-query";
import clientsApi                 from "@/api/clients-api";
import { useToast }               from "vue-toastification";


import type { IMailboxes }        from "@/modules/settings/interfaces/Mailboxes";
import SettingsParser             from "@/modules/settings/utils/parser/settings";
import { numberToWords }          from "@/modules/settings/utils/helpers/numbers";
import { useMailboxesStore }      from "@/modules/settings/stores/mailboxes";
import { useModalStore }          from "@/modules/shared/stores/modal";
import useInputMultiple           from "@/modules/shared/composable/useInputMultiple";
import MODALS                     from "@/modules/shared/utils/modals_enum";

const getMailboxes = async():Promise<IMailboxes[]> => {
  const { data } = await clientsApi.get("/enterprise/settings/mailboxes/");
  return SettingsParser.parseMailboxes(data.results);
};

const createMailboxes = async( data: any):Promise<any> => {
  try {
    const { data:dataResp } = await clientsApi.post<any>("/enterprise/settings/mailboxes/", data.mailboxes );
    const query = data.queryClient.getQueryCache().find(["mailboxes"],{ exact: true });
    query.fetch();
    return dataResp;
  } catch (error:any) {
    const query = data.queryClient.getQueryCache().find(["mailboxes"],{ exact: true });
    query.fetch();
    console.error(error);
  }
};

const deleteMailboxes = async( data: any ):Promise<any> => {
  const { data:dataResp } = await clientsApi.delete<any>("/enterprise/settings/mailboxes/", { data: JSON.stringify({emails: data.data})  } );
  const query = data.queryClient.getQueryCache().find(["mailboxes"],{ exact: true });
  query.fetch();
  return dataResp;
};

const useMailboxes = () => {
  const storeMailboxes  = useMailboxesStore();
  const storeModal      = useModalStore();
  const { mailboxes }   = storeToRefs( storeMailboxes );
  const toast           = useToast();

  const { email:emailInputMultiple, emails }      = useInputMultiple();

  const getUseMailboxes = useQuery( [ "mailboxes"], getMailboxes );
  watch( getUseMailboxes.data, mailboxes => {
    if( mailboxes ) storeMailboxes.setMailboxes(mailboxes);
  }, { immediate: true });

  const mailboxesCreateMutation = useMutation( createMailboxes );
  watch( mailboxesCreateMutation.isSuccess, (isSuccess) => {
    if(isSuccess){
      storeModal.hideModal();
      const m = emails.value.length;
      toast.success(`${numberToWords(m)} new mailbox${ m > 1 ? "es" : "" } added!`);
      emails.value = [];
    }
  });

  const mailboxesDeleteMutation = useMutation( deleteMailboxes );
  watch( mailboxesDeleteMutation.isSuccess, (isSuccess) => {
    if(isSuccess) {
      storeModal.hideModal();
      const n = mailboxesDeleteMutation.variables.value.data.length;
      toast.success(`${numberToWords(n)} mailbox${n > 1 ? "es" : ""} removed`);
    }
  });

  // check Table
  const selectedItems = ref<string[]>([]);
  const indeterminate = computed(() => selectedItems.value.length > 0 && selectedItems.value.length < mailboxes.value.length);
  const selectAll = (e:any) => {
    selectedItems.value = e.target.checked ? mailboxes.value.map((p:any) => p.email) : [];
  };
  const selectItem = (e:any, email:string) => {
    e.target.checked 
      ? selectedItems.value = [email, ...selectedItems.value] 
      : selectedItems.value = selectedItems.value.filter((item) => item !== email);
  };
  const unselectAll = () => {
    selectedItems.value = [];
  };
  // check Table


  return {
    // properties
    mailboxes,
    selectedItems,

    // Method
    createMailboxes: mailboxesCreateMutation,
    deleteMailboxes: mailboxesDeleteMutation,
    selectAll,
    selectItem,
    removeModal: (mailboxes:string[], useQueryClient:any) =>{
      const title = `Are you sure you want to remove ${ mailboxes.length > 1 ? "these mailboxes" : "this mailbox" }?`;
      const text = `We’ll stop collecting email statistics for ${ mailboxes.join(", ") }.`;

      storeModal.showModal({
        name: MODALS.removeModal,
        data: {
          type: "mailboxes",
          title,
          text,
          data: mailboxes,
          useQueryClient,
          deleteEmails: mailboxes
        }
      });
      unselectAll();
    },
    showModal: () =>{
      emailInputMultiple.value = "";
      emails.value = [];
      const data = { name: MODALS.addMailBoxes, data: {} };
      storeModal.showModal(data);
    },

    // computed
    // get mailboxes
    getData:            computed(()=> getUseMailboxes.data ),
    isLoadingGet:       computed(()=> getUseMailboxes.isLoading.value ),
    isErrorGet:         computed(()=> getUseMailboxes.isError.value ),
    
    // creating mailboxes
    isCreating:         computed( () => mailboxesCreateMutation.isLoading.value ),
    isCreatingSuccess:  computed( () => mailboxesCreateMutation.isSuccess.value ),
    isErrorCreating:    computed( () => mailboxesCreateMutation.isError.value ),

    // delete mailboxes
    isRemoved:        computed( () => mailboxesDeleteMutation.isLoading.value ),
    isRemovedSuccess: computed( () => mailboxesDeleteMutation.isSuccess.value ),
    isRemovedError:   computed( () => mailboxesDeleteMutation.isError.value ),

    onlyExistEmails:    computed(()=> [...mailboxes.value.map(m => m.email)]),
    getMailboxesLength: computed(()=> mailboxes.value.length),
    indeterminate,
  };
};

export default useMailboxes;
