import { watch }              from "vue";
import { storeToRefs }        from "pinia";
import { useQuery }           from "@tanstack/vue-query";
import clientsApi             from "@/api/clients-api";

import UserStatusParser       from "@/modules/shared/utils/parser/userStatus";
import type { IUserStatus }   from "@/modules/shared/interfaces/UserStatus";

import { useUserStatusStore } from "@/modules/shared/stores/userStatus";

const getUserStatus = async():Promise<IUserStatus> => {
  const { data } = await clientsApi.get("/enterprise/onboarding/status/");
  return UserStatusParser.parseUserStatus(data);
};

const userStatus = () => {
  const storeUserStatus  = useUserStatusStore();
  const { userStatus }   = storeToRefs( storeUserStatus );

  const { isLoading, data } = useQuery(
    [ "userStatus"],
    getUserStatus
  );
  watch( data, userStatus => {
    if( userStatus ) storeUserStatus.setUserStatus(userStatus);
  }, { immediate: true });

  return {
    userStatus,
    isLoading,
  };
};

export default userStatus;
