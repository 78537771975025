<script setup lang="ts">
import type { PropType } from "vue";
import useInputMultiple from "@/modules/shared/composable/useInputMultiple";
import { XIcon } from "@heroicons/vue/solid";

const props = defineProps({
  checkMails: {
    type: Object as PropType<string[]>,
    required: true,
  },
  allowed_domains: {
    type: Object as PropType<string[]>,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
});

const { email, emails, insert, DeleteLastEmail, ChangeEmail, DeleteEmail } = useInputMultiple();

const EmailD = (email: string) => {
  if (!props.disabled) DeleteEmail(email);
};
const placeholder = () => !emails.value.length ? "Type in or paste a list of mailboxes" : "";
</script>

<template>
  <div class="relative box-border ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-500 rounded-md">
    <div
      class="bg-white shadow-xs py-1.75 px-3 max-h-52 overflow-y-auto !border-none rounded-md appearance-none flex whitespace-normal"
      :class="{ '!bg-gray-50 ': disabled }">
      <div v-if="!disabled" class="text-sm flex basis-full grow flex-wrap py-0 px-0.5 relative">
        <span v-for="(e, i) in emails" :key="i" :class="{ 'error border-red-400 text-red-400': !e.status }"
          class="border border-gray-300 py-0.5 pr-1 pl-2.5 font-medium text-xs leading-4 text-center bg-transparent my-0.5 mr-1.5 text-gray-800 rounded">
          {{ e.email }}

          <XIcon class="w-3 h-3 text-gray-300 inline-block" @click="EmailD(e.email)" />
        </span>
        <input id="inputAddEmail" ref="inputAddEmail" v-model="email" type="text"
          class="placeholder-gray-500 p-0 min-w-1/2 text-sm leading-5 m-0 text-gray-700 !border-none appearance-none outline-none bg-none shadow-none w-0 max-w-full grow z-10 focus:ring-0 focus:ring-offset-0"
          :disabled="disabled" :placeholder="placeholder()"
          @keyup.enter.space="insert(props.checkMails, props.allowed_domains)"
          @keyup.,="insert(props.checkMails, props.allowed_domains)"
          @keydown.tab.prevent="insert(props.checkMails, props.allowed_domains)" @keyup.delete="DeleteLastEmail"
          @input="ChangeEmail">
      </div>
      <input v-else
        class="p-0 min-w-1/2 text-sm leading-5 m-0 !border-none appearance-none outline-none bg-none shadow-none w-0 max-w-full grow z-10 focus:ring-0 focus:ring-offset-0 !bg-gray-50 !text-gray-500 !cursor-not-allowed"
        type="text" disabled :value="emails[0].email">
    </div>
  </div>
</template>

<style scoped></style>