<script setup lang="ts">
import { ref }              from "vue";
import Multiselect          from "vue-multiselect";
import { useQueryClient }   from "@tanstack/vue-query";
import { storeToRefs }      from "pinia";
import { useToast }         from "vue-toastification";

import { Listbox, ListboxLabel }  from "@headlessui/vue";

import InputMultiple              from "@/modules/shared/components/InputMultiple.vue";
import ButtonsModal               from "@/modules/shared/components/ButtonsModal.vue";
import useInputMultiple           from "@/modules/shared/composable/useInputMultiple";
import useUserInfo                from "@/modules/shared/composable/useUserInfo";
import { useInputMultipleStore }  from "@/modules/shared/stores/inputMultiple";
import { useModalStore }          from "@/modules/shared/stores/modal";
import useUsers                   from "@/modules/settings/composables/useUsers";
import useMailboxes               from "@/modules/settings/composables/useMailboxes";
import { useMailboxesStore }      from "@/modules/settings/stores/mailboxes";
import useUserStatus                 from "@/modules/shared/composable/useUserStatus";


const toast                                       = useToast();
const queryClient                                 = useQueryClient();
const { userStatus }                              = useUserStatus();
const { userInfo }                                = useUserInfo();
const { onlyExistEmails, createUsers, dataModal } = useUsers();
const { email, emails, insert, onlyEmails }       = useInputMultiple();
const store                                       = useInputMultipleStore();
const storeMailboxes                              = useMailboxesStore();

const { emails:emailsInput }                      = storeToRefs( store );
const { mailboxes }                               = storeToRefs( storeMailboxes );
const { onlyExistEmails:mailboxesEmails }         = useMailboxes();
const storeModal                                  = useModalStore();

const hasDataModal = !!Object.keys(dataModal.value).length;

if(hasDataModal) emailsInput.value = [{ email: dataModal.value.email, status: true, notTraking: !(mailboxes.value.filter((p:any) => (p.email === dataModal.value.email)).length > 0) }];

const focusOut = () => { email.value.length > 0 && insert(onlyExistEmails.value, userInfo.value!.allowedDomains); };

const roles = [
  { id: 1, name: "Admin", description: "Can see email statistics for all tracked mailboxes." },
  { id: 2, name: "Manager", description: "Can see email statistics only for the mailboxes you give permission to." },
  { id: 3, name: "Viewer", description: "Can only see their own email statistics." },
];

const rol_map:any = {
  "Admin": 0,
  "Manager": 1,
  "Viewer": 2,
};

const userRoleTitle = ref([
  {
    title: "Admin",
    description: "Can see email statistics for all tracked mailboxes.",
  },
  {
    title: "Manager",
    description: "Can see email statistics only for the mailboxes you give permission to.",
  },
  {
    title: "Viewer",
    description: "Can only see their own email statistics.",
  },
]);


const role = ref(hasDataModal ? userRoleTitle.value.find((i)=> i.title === dataModal.value.role) : "");

const value     = ref(hasDataModal ? dataModal.value.mailboxes.map((m:any)=> { return { "name": m, "code": m }; }) : []);
const options   = ref(mailboxesEmails.value.map((m)=> { return { "name": m, "code": m }; }));

const selected  = ref(hasDataModal ? roles[rol_map[dataModal.value.role]] : { id: 0, name: "Choose user role" });

const isDisabled = () => {
  if (role.value?.title === "Manager" && value.value.length === 0) return true;
  if (email.value.length === 0 && emails.value.length > 0 && !isDisabledText() && !(createUsers.isLoading.value || createUsers.isSuccess.value) && Boolean(role.value?.title)) return false;
  return true;
};

const isDisabledText = () => emails.value.filter((e) => e.status === false).length > 0;

const statusDisabled = () => hasDataModal;

const ConfirmAddMail = () => {
  if ((onlyEmails().length + onlyExistEmails.value.length) <= userStatus.value!.maxUsers) {
    const newUserData = {
      emails: onlyEmails(),
      role: role.value?.title.toLowerCase(),
      manage_mailboxes: value.value.length > 0 ? value.value.map((m:any)=> { return m.name; }) : [],
    };

    if(newUserData.role == "viewer" && emails.value.some((p) => p.notTraking)){
      storeModal.hideModal();
      toast.error(`${ emails.value.length > 1 ? "These users doesn’t": "This user don’t"} have a tracked ${ emails.value.length > 1 ? "mailboxes": "mailbox"}`);
    } else {
      const data = hasDataModal ? { type: "edit", users: newUserData, queryClient: queryClient } : { users: newUserData, queryClient: queryClient }; 
      createUsers.mutate(data);
    }
  }
};
</script>

<template>
  <div>
    <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">{{ hasDataModal ? 'Edit role' : 'Add new users' }}</p>
    <div class="p-6 border-b">
      <p v-if="!statusDisabled()" class="text-sm leading-5 font-normal text-gray-700 pb-8" @click="focusOut">Enter the email addresses of the users you’d like to add and choose their roles.</p>
      <div class="pb-6">
        <label for="email" class="block text-sm font-medium leading-5 text-gray-700 pb-1" @click="focusOut">Email address</label>
        <InputMultiple :checkMails="onlyExistEmails" :allowed_domains="userInfo!.allowedDomains" :disabled="statusDisabled()" />
        <span v-show="isDisabledText()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
          Please check that all email addresses are correct.
        </span>
      </div>
      <Listbox as="div" v-model="selected" @click="focusOut">
        <ListboxLabel class="block text-sm font-medium leading-5 text-gray-700">User role</ListboxLabel>
        <div class="relative mt-1">
          <v-select
            placeholder="Choose user role"
            v-model="role"
            :searchable="false"
            class="style-chooser ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-500 rounded-md"
            :options="userRoleTitle"
            label="title"
          >
            <template #option="{ title, description }">
              <span class="text-sm text-gray-900 leading-5">{{ title }}</span>
              <br />
              <span class="text-sm text-gray-500 leading-5">{{ description }}</span>
            </template>
          </v-select>
        
        </div>
      </Listbox>
      <div v-if="role.title === 'Manager'" class="pt-6">
        <label for="location" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Managed mailboxes</label>
        <Multiselect
          class="campo-email"
          v-model="value"
          :options="options"
          placeholder="Select mailboxes"
          label="name"
          track-by="name"

          :multiple="true"
          :close-on-select="false"
        />
      </div>
    </div>
    <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddMail" />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>