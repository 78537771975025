import { computed, ref } from "vue";

const useTable = (items:any, order:string) => {
  
  const search = ref<string>("");
  const asc = ref<boolean>(true);
  const typeOrder = ref<string>(order);

  return {
    // variable
    asc,
    search,
    typeOrder,

    // Methods
    filterInputVal: ( e: string) => {
      search.value = e;
    },
    order: (o:string) => {
      asc.value = typeOrder.value !== o ? true : !asc.value;
      typeOrder.value = o;
    },
    // composable
    data: computed(() =>{
      const is = items.value.filter((item: any) => {
        for (const attr of ["email", "name", "department"]) {
          if(item[attr] && item[attr].toLowerCase().includes(search.value.toLowerCase())) return item;
        }
      }).sort((a:any, b:any) => {
        let nameA;
        let nameB;

        if(["dataAdded", "created"].includes(typeOrder.value)){
          const d1 = <any>new Date(a[typeOrder.value]);
          const d2 = <any>new Date(b[typeOrder.value]);
          return (asc.value) ? d1 - d2 : d2 - d1;
        } else if(typeOrder.value === "team-mailboxes"){
          nameA = a.mailboxes.length;
          nameB = b.mailboxes.length;
        } else if(typeOrder.value === "tracking"){
          nameA = b.tracking;
          nameB = a.tracking;
        } else if(typeOrder.value === "access-to"){
          nameA = a.role === "Admin" ? 0 : a.dataFrom.length == 0 ? 1 : a.dataFrom.length + 1;
          nameB = b.role === "Admin" ? 0 : b.dataFrom.length == 0 ? 1 : b.dataFrom.length + 1;
        } else {
          nameA = a[typeOrder.value].toUpperCase();
          nameB = b[typeOrder.value].toUpperCase();
        }

        const orderby = asc.value ? [-1, 1] : [1, -1];
        if (nameA < nameB) return orderby[0];
        if (nameA > nameB) return orderby[1];
        return -1;
      });
      return is;
    })
  };
};

export default useTable;