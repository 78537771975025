<script setup lang="ts">
import { onBeforeMount }        from "vue";
import { useRouter }  from "vue-router";
import ModalComponent           from "@/modules/shared/components/ModalComponent.vue";

const router = useRouter();

onBeforeMount(() => {
  const url = new URL(window.location);
  const savedToken = localStorage.getItem("userToken");
  const urlToken = url.searchParams.get("token");

  if(savedToken || urlToken){
    localStorage.setItem("userToken", urlToken! ?? savedToken);
    if(urlToken) router.push({ name: "dashboard" });
  } else router.push({ name: "login" });
});
</script>

<template>
  <ModalComponent />
  <RouterView />
</template>

<style scoped></style>
