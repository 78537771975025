import { ref } from "vue";
import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", () => {
  const display = ref<Boolean>(false);
  const hasCloseButton = ref<Boolean>(true);
  const activeModal = ref<String>("");
  const dataModal = ref<String>();

  const showModal = (data:any) => {
    activeModal.value = data.name;
    dataModal.value = data.data;

    display.value = true;
  };
  const hideModal = () => { display.value = false; };

  return {
    // Properties
    display,
    hasCloseButton,
    activeModal,
    dataModal,

    // Methods
    showModal,
    hideModal
  };
});
