<script setup lang="ts">
import PanelLayout             from "@/modules/shared/pages/PanelLayout.vue";
import useDashboard            from "@/modules/dashboard/composables/useDashboard";
import { TemplateIcon, ExternalLinkIcon }  from "@heroicons/vue/outline";

const { dashboard, href } = useDashboard();
</script>

<template>
  <PanelLayout>
    <template v-slot:dashboard>
      <div class="w-full px-8 py-6 pb-0 overflow-y-hidden">
        <div class="flex justify-between w-full items-start">
          <div>
            <p class="text-left font-bold whitespace-normal break-all p-0 xl:my-3 my-1 xl:text-3xl text-2xl text-gray-800">Your dashboard</p>
            <p class="m-0 text-left text-base text-gray-500 leading-6 ">Measure your team’s email activity and track KPIs</p>
          </div>
      
          <span
            :class="{'!cursor-not-allowed !bg-gray-100 !text-gray-400 !border-gray-300': !dashboard.length}"
            class="cursor-default border-gray-300 bg-white rounded mt-3 px-3 py-2 border inline-flex no-underline font-inter font-medium text-sm text-gray-700 hover:bg-gray-50"  
            target="_blank"
            @click="href"
          >
            <ExternalLinkIcon class="h-5 w-5 text-gray-500 my-auto" />
            <span class="my-0 self-center ml-2">Open in Looker Studio</span>
          </span>
        </div>
        <div class="h-full mt-6 rounded-t-lg border border-solid border-gray-200 bg-white shadow-default">
          <iframe
            v-if="dashboard.length"
            :src="dashboard[0].embedded_url"
            class="h-full shadow rounded-t-lg"
            style="border: none"
            height="92%"
            width="100%"
            frameborder="0"
          />
          <div v-else class="flex h-full flex-col justify-center items-center">
            <div class="p-3.5 bg-indigo-100 rounded-full">
              <TemplateIcon class="w-5 h-5 text-indigo-600" />
            </div>
            <p class="text-gray-900 text-base leading-6 font-medium pt-2">Your dashboard will be ready soon</p>
            <p class="text-gray-500 text-sm leading-5 w-2/5 text-center pt-1">We’re loading your data and getting your metrics ready for you! If you have any questions or want an update, please get in touch.</p>
            <div class="p-6 flex justify-center">
              <a href="mailto:evalocci@emailmeter.com" target="_blank" class="py-2.25 px-4 text-sm bg-indigo-500 text-white hover:bg-indigo-600 rounded-md">
                Contact your Account Manager
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PanelLayout>
</template>

<style scoped>

</style>