<script setup lang="ts">
import { ref }            from "vue";
import Multiselect        from "vue-multiselect";
import { useQueryClient } from "@tanstack/vue-query";
import { useToast }       from "vue-toastification";

import ButtonsModal       from "@/modules/shared/components/ButtonsModal.vue";
import useMailboxes       from "@/modules/settings/composables/useMailboxes";
import useTeams           from  "@/modules/settings/composables/useTeams";
import { useModalStore }  from "@/modules/shared/stores/modal";

/*  */
const { onlyExistEmails:mailboxesEmails}   = useMailboxes();
const queryClient                          = useQueryClient();
const { createTeams, dataModal, teams }    = useTeams();
const toast                                = useToast();
const storeModal                           = useModalStore();

const hasDataModal = !!Object.keys(dataModal.value).length;

const departament = ref(hasDataModal ? dataModal.value.departament : "");

const value     = ref(hasDataModal ? dataModal.value.mailboxes.map((m:string)=> { return { "name": m, "code": m }; }) : []);
const options   = ref(mailboxesEmails.value.map((m)=> { return { "name": m, "code": m }; }));
/*  */

const isDisabled = () => !(value.value.length > 0 && departament.value !== "");

const ConfirmAddTeam = () => {
  if(!hasDataModal && teams.value.some((t) => t.department === departament.value)) {
    storeModal.hideModal();
    toast.error("This team name already exists");
  } else {
    const newTeamsData = {
      department: departament.value,
      mailboxes: value.value.length > 0 ? value.value.map((m:any)=> { return m.name; }) : [],
    };
    const data = hasDataModal ? { type: "edit", teams: newTeamsData, queryClient: queryClient } : { teams: newTeamsData, queryClient: queryClient }; 
    createTeams.mutate(data);
  }


};
</script>

<template>
  <div>
    <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">{{ hasDataModal ? 'Edit team' : 'Create new team' }}</p>
    <div class="p-6 border-b">
      <div class="pb-6">
        <label for="departament" class="block text-sm font-medium leading-5 text-gray-700">Team name</label>
        <div class="mt-1">
          <input
            id="departament"
            type="text"
            name="departament"
            v-model="departament"
            class="block w-full rounded-md border-0 py-2 px-3.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-5"
            :class="{'!bg-gray-50 !text-gray-500 !cursor-not-allowed': hasDataModal}"
            placeholder="Enter your team’s name"
            :disabled="hasDataModal"
          />
        </div>
      </div>
      <div>
        <label for="location" class="block text-sm font-medium leading-5 text-gray-700 pb-1">Mailboxes</label>
        <Multiselect
          class="campo-email"
          v-model="value"
          tag-placeholder="Add this as new tag"
          placeholder="Select mailboxes"
          label="name"
          track-by="name"
          :options="options"
          :multiple="true"
          :close-on-select="false"
        />

      </div>
    </div>

    <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddTeam" />
  </div>
</template>

<style scoped>

</style>