import { computed, ref, watch }        from "vue";
import { storeToRefs }            from "pinia";
import { useMutation, useQuery }  from "@tanstack/vue-query";
import clientsApi                 from "@/api/clients-api";

import type { ITeams }            from "@/modules/settings/interfaces/Teams";
import { useTeamsStore }          from "@/modules/settings/stores/teams";
import { useModalStore }          from "@/modules/shared/stores/modal";
import MODALS                     from "@/modules/shared/utils/modals_enum";
import { useToast }               from "vue-toastification";


const getTeams = async():Promise<ITeams[]> => {
  const { data } = await clientsApi.get("/enterprise/settings/teams/");
  return data.results;
};

const createTeams = async( data: any):Promise<any> => {
  const { data:dataResp } = await clientsApi.post<any>("/enterprise/settings/teams/", data.teams );
  const query = data.queryClient.getQueryCache().find(["teams"],{ exact: true });
  query.fetch();
  return dataResp;
};

const deleteTeams = async( data: any ):Promise<any> => {
  const { data:dataResp } = await clientsApi.delete<any>("/enterprise/settings/teams/", { data: JSON.stringify({departments: data.data})  } );
  const query = data.queryClient.getQueryCache().find(["teams"],{ exact: true });
  query.fetch();
  return dataResp;
};

const useTeams = () => {
  const store         = useTeamsStore();
  const storeModal    = useModalStore();
  const { teams }     = storeToRefs( store );
  const { dataModal } = storeToRefs( storeModal );
  const toast         = useToast();


  const getUseTeams = useQuery( [ "teams"], getTeams );
  watch( getUseTeams.data, teams => {
    if( teams ) store.setTeams(teams);
  }, { immediate: true });


  const teamsDeleteMutation = useMutation( deleteTeams );
  watch( teamsDeleteMutation.isSuccess, (isSuccess) => {
    if(isSuccess) {
      storeModal.hideModal();
      toast.success(`Team${teamsDeleteMutation.variables.value.data.length > 1 ? "s" : ""} deleted`);
    }
  });

  const teamsCreateMutation = useMutation( createTeams );
  watch( teamsCreateMutation.isSuccess, (isSuccess) => {
    if(isSuccess){
      storeModal.hideModal();
      teamsCreateMutation.variables.value.type ? toast.success("Team edited") : toast.success("Team created");
    }
  });

  // check Table
  const selectedItems = ref<string[]>([]);
  const indeterminate = computed(() => selectedItems.value.length > 0 && selectedItems.value.length < teams.value.length);
  const selectAll = (e:any) => {
    selectedItems.value = e.target.checked ? teams.value.map((p:any) => p.department) : [];
  };
  const selectItem = (e:any, department:string) => {
    e.target.checked 
      ? selectedItems.value = [department, ...selectedItems.value] 
      : selectedItems.value = selectedItems.value.filter((item) => item !== department);
  };
  const unselectAll = () => {
    selectedItems.value = [];
  };
    // check Table

  return {
    teams,
    selectedItems,
    dataModal,

    // Method
    createTeams: teamsCreateMutation,
    deleteTeams: teamsDeleteMutation,
    selectAll,
    selectItem,
    removeModal: (teams:string[], useQueryClient:any) =>{
      const maxLength = 50;
      const ts = teams.map(t => {
        return t.length > maxLength ? t.slice(0, maxLength) + "..." : t;
      });
      const title = `Are you sure you want to remove ${ teams.length > 1 ? "these teams" : "this team" }?`;
      const text = `The "${ ts.join(", ") }" team will be permanently deleted, but the mailboxes inside it will still be tracked.`;

      storeModal.showModal({
        name: MODALS.removeModal,
        data: {
          type: "teams",
          title,
          text,
          data: teams,
          useQueryClient,
          deleteEmails: teams
        }
      });
      unselectAll();
    },

    showModal: (data:any) =>{
      storeModal.showModal({ name: MODALS.addTeams, data });
    },

    // computed
    // get mailboxes
    getData:      computed(()=> getUseTeams.data ),
    isLoadingGet: computed(()=> getUseTeams.isLoading.value ),
    isErrorGet:   computed(()=> getUseTeams.isError.value ),

    getTeamsLength: computed(()=> teams.value.length),
    indeterminate,
  };
};

export default useTeams;
