<script setup lang="ts">
import { watch, ref }       from "vue";
import PanelLayout          from "@/modules/shared/pages/PanelLayout.vue";
import { useRoute }         from "vue-router";
import { SETTINGS_ROUTES }  from "@/modules/settings/utils/constants/routes"; 

const route = useRoute();

const tabs  = ref([
  { name: "Mailboxes",  href: SETTINGS_ROUTES.MailboxesSettings,  current: false },
  { name: "Users",      href: SETTINGS_ROUTES.UsersSettings,      current: false },
  { name: "Teams",      href: SETTINGS_ROUTES.TeamsSettings,      current: false },
]);

watch(route, (newVal) => {
  Object.values(tabs.value).forEach((_:number, index:number) => {
    tabs.value[index].current = false;
  });

  ["MailboxesSettings", "UsersSettings", "TeamsSettings"].forEach((settingRoute: string, index:number) => {
    if(newVal.name == SETTINGS_ROUTES[settingRoute as "MailboxesSettings" | "UsersSettings" | "TeamsSettings"]) tabs.value[index].current = true;
  });
}, { immediate: true });
</script>

<template>
  <PanelLayout>
    <template v-slot:header>
      <div class="w-full px-8 py-6 pb-0 overflow-y-auto">
        <p class="text-gray-800 text-3xl font-bold leading-9">Settings</p>
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <router-link v-for="tab in tabs" :to="{ name: tab.href}" :key="tab.name" :class="[tab.current ? 'border-indigo-500 text-indigo-500' : 'border-transparent transition duration-0 hover:duration-200 text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']">{{ tab.name }}</router-link>
          </nav>
        </div>
        <RouterView />
      </div>
    </template>
  </PanelLayout>
  
</template>

<style scoped>

</style>
