import type { IUserStatusInitial } from "@/modules/shared/interfaces/UserStatus";

export default class UserStatusParser {
  static parseUserStatus(data: IUserStatusInitial) {
    return {
      "completed": data.completed,
      "enterpriseType": data.enterprise_type,
      "hasCustomLabels": data.has_custom_labels,
      "hasManagers": data.has_managers,
      "hasMultipleBhs": data.has_multiple_bhs,
      "hasMultipleTimezones": data.has_multiple_timezones,
      "hasTeams": data.has_teams,
      "maxMailboxes": data.max_mailboxes,
      "maxUsers":data.max_users,
      "step": data.step,
      "dashboardReady": data.is_dashboard_ready,
    };
  }
}
