import { storeToRefs }    from "pinia";
import { computed } from "vue";

import { useInputMultipleStore }  from "@/modules/shared/stores/inputMultiple";
import { useMailboxesStore }      from "@/modules/settings/stores/mailboxes";


const useInputMultiple = () => {
  const store           = useInputMultipleStore();
  const storeMailboxes  = useMailboxesStore();

  const { count, email, emails }  = storeToRefs( store );
  const { mailboxes }             = storeToRefs( storeMailboxes );

  const reg = /^[_a-z0-9-]+(.[_a-z0-9-\+]+)@[a-z0-9-]+(.[a-z0-9-]+)(.[a-z]{2,3})$/;
  const removeEmpty = (array:string[]) => array.filter((str) => (typeof str === "string" ? Boolean(str.trim()) : true));
  const validEmail = (emails:string, checkMails:string[], allowed_domains:string[]) => {
    return removeEmpty(emails.split(",")).map((email) => {
      const e = email.trim();

      if (!reg.test(e)) return { email: e, status: false };
      if(checkMails && allowed_domains){
        if (!allowed_domains.includes(e.split("@")[1])) return { email: e, status: false };
        if (checkMails.includes(e)) return { email: e, status: false };
      }
      return { email: e, status: true, notTraking: !(mailboxes.value.filter((p:any) => (p.email === e)).length > 0) };
    });
  };

  return {
    // variable
    email,
    emails,

    // Methods
    insert: (checkMails:any[], allowed_domains:any[]) => {
      if(email.value.length > 0){
        const validEmails = [
          ...emails.value,
          ...validEmail(email.value, checkMails, allowed_domains),
        ];
        store.setEmails(validEmails);
        store.setEmail("");
      }
    },
    ChangeEmail: (e:any) => {
      store.setEmail(e.target.value);
      store.setCount(0);
    },
    DeleteEmail: (email:string) => {
      store.setEmails([...emails.value.filter((e) => e.email !== email)]);
    },
    DeleteLastEmail: () => {
      if (email.value.length == 0)  store.setCount(count.value + 1);
      if (count.value > 1) store.setEmails([...emails.value.filter((e) => e !== emails.value[emails.value.length - 1]),]);
    },
    onlyEmails: () => emails.value.map((e) => e.email),
    // composable
    emailsLength: computed(() => {
      return emails.value.length;
    }),
  };
};

export default useInputMultiple;