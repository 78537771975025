<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import PageTitleSettings  from "@/modules/settings/components/PageTitleSettings.vue";
import UsersTable         from "@/modules/settings/components/table/taps/UsersTable.vue";
import useTable           from "@/modules/settings/composables/useTable";
import useUsers           from "@/modules/settings/composables/useUsers";
import useUserStatus      from "@/modules/shared/composable/useUserStatus";

const {
  users, isLoadingGet, showModal, removeModal, isErrorGet, getUsersLength, selectedItems, indeterminate, selectAll, selectItem
}                    = useUsers();
const { filterInputVal, search, data, order, asc, typeOrder
}                    = useTable(users, "email");
const { userStatus } = useUserStatus();
const queryClient    = useQueryClient();

const remove = (e:any) => { removeModal(e, queryClient); };
</script>

<template>
  <PageTitleSettings
    title="Users"
    description="Manage who has access to the dashboard"
    button="Add new users"
    :search="search"
    :dataLength="getUsersLength"
    :max="userStatus?.maxUsers"
    @showModal="showModal"
    @changeSearch="filterInputVal"
  />
    
  <UsersTable
    :data="data"
    :search="search"
    :asc="asc"
    :isLoading="isLoadingGet"
    :isErrorGet="isErrorGet"
    :dataLength="getUsersLength"
    :typeOrder="typeOrder"

    :selectedItems="selectedItems"
    :indeterminate="indeterminate"
    @selectAll="selectAll"
    @selectItem="selectItem"

    @orderBy="order"
    @showModal="showModal"
    @removeModal="remove"
    @clearSearch="() => { filterInputVal('') }"
  />
</template>

<style scoped></style>