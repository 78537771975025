const parseUserInfo = (data: any) => {

  const role = data.roles
    .filter((role: string) => role.includes("enterprise-portal"))
    .map((role: string) => role.replace("enterprise-portal-", ""));

  const isAdmin = role.filter((role: string) => role.includes("admin"));

  return {
    "allowedDomains": data.client.allowed_domains,
    "email": data.email,
    "isAdmin": Boolean(isAdmin.length),
    "name": `${data.first_name} ${data.last_name}`,
    "roles": role,
  };
};
export default parseUserInfo;
