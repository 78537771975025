import { ref } from "vue";
import { defineStore } from "pinia";
// import type { IMailboxes } from "@/modules/settings/interfaces/Mailboxes";


export const useDashboardStore = defineStore("dashboard", () => {

  const dashboard = ref<any>([]);

  return {
    // properties
    dashboard,

    // Actions
    setDashboard( newDashboard: any ) { dashboard.value = newDashboard; },
  };
});
