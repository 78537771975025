<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import MailboxesTable     from "@/modules/settings/components/table/taps/MailboxesTable.vue";
import PageTitleSettings  from "@/modules/settings/components/PageTitleSettings.vue";
import useMailboxes       from "@/modules/settings/composables/useMailboxes";
import useTable           from "@/modules/settings/composables/useTable";
import useUserStatus         from "@/modules/shared/composable/useUserStatus";

const { userStatus }      = useUserStatus();
const queryClient         = useQueryClient();

const {
  getMailboxesLength, mailboxes, isLoadingGet, isErrorGet, removeModal, showModal, selectedItems, indeterminate, selectAll, selectItem,
}            = useMailboxes();
const { filterInputVal, search, data, order, asc, typeOrder
}            = useTable(mailboxes, "email");
const remove = (e:any) => { removeModal(e, queryClient); };
</script>

<template>
  <PageTitleSettings
    title="Mailboxes"
    description="Manage the email accounts you’re tracking email statistics for"
    button="Add mailboxes"
    :search="search"
    :dataLength="getMailboxesLength"
    :max="userStatus?.maxMailboxes"
    @showModal="showModal"
    @changeSearch="filterInputVal"
  />

  <MailboxesTable
    :data="data"
    :search="search"
    :asc="asc"
    :isLoading="isLoadingGet"
    :isErrorGet="isErrorGet"
    :dataLength="getMailboxesLength"
    :typeOrder="typeOrder"

    :selectedItems="selectedItems"
    :indeterminate="indeterminate"
    @selectAll="selectAll"
    @selectItem="selectItem"

    @showModal="showModal"
    @removeModal="remove"
    @clearSearch="() => { filterInputVal('') }"
    @orderBy="order"
  />
</template>

<style scoped></style>
