import { ref } from "vue";
import { defineStore } from "pinia";

export const useInputMultipleStore = defineStore("InputMultiple", () => {
  const email = ref<string>("");
  const emails = ref<{email:string, status:boolean, notTraking?: boolean}[]>([]);
  const count = ref<number>(0);

  return {
    // Properties
    email,
    emails,
    count,

    // Methods
    setEmail: (string:string) => { email.value = string; },
    setEmails: (es:any) => { emails.value = es; },
    setCount: (c:number) => { count.value = c; },
  };
});
