<script setup lang="ts">
import { ref } from "vue";
import { TrashIcon }      from "@heroicons/vue/outline";
import useRemoveModal     from "@/modules/settings/composables/useRemoveModal";
import useMailboxes       from "@/modules/settings/composables/useMailboxes";
import useUsers           from "@/modules/settings/composables/useUsers";
import useTeams           from "@/modules/settings/composables/useTeams";
import ButtonsModal       from "@/modules/shared/components/ButtonsModal.vue";

const { deleteMailboxes } = useMailboxes();
const { deleteUsers }     = useUsers();
const { deleteTeams }     = useTeams();
const { dataModal }       = useRemoveModal();

const isDisabled = ref<boolean>(false);

const remove = () => {
  const data = { data: dataModal.value.data, queryClient: dataModal.value.useQueryClient };
  if (dataModal.value.type === "mailboxes") {
    deleteMailboxes.mutate(data);
    isDisabled.value = deleteMailboxes.isLoading.value || deleteMailboxes.isSuccess.value;
  }
  if (dataModal.value.type === "users"){
    deleteUsers.mutate(data);
    isDisabled.value = deleteUsers.isLoading.value || deleteUsers.isSuccess.value;
  }
  if (dataModal.value.type === "teams"){ 
    deleteTeams.mutate(data);
    isDisabled.value = deleteTeams.isLoading.value || deleteTeams.isSuccess.value;
  }
};
</script>

<template>
  <div class="p-6 pb-0">
    <div class="bg-red-100 w-12 h-12 flex items-center justify-center rounded-full ">
      <TrashIcon class="w-6 h-6 text-red-500" />
    </div>

    <p class="text-gray-900 font-semibold leading-8 text-lg mt-5">{{ dataModal.title }}</p>
    <p class="text-sm leading-5 font-normal text-gray-500 pt-2">{{ dataModal.text }}</p>

    <div v-if="dataModal.type === 'mailboxes'" class="pt-5">
      <fieldset>
        <div class="relative flex items-start">
          <div class="flex h-6 items-center">
            <input id="remove" aria-describedby="remove-description" name="remove" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0" />
            <p class="ml-2 text-sm leading-5 font-medium text-gray-700">Delete all their existing email data from the dashboard</p>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <ButtonsModal
    type="Remove"
    color="red"
    :isDisabled="isDisabled"
    @submit="remove"
  />
</template>

<style scoped></style>
