import SettingsLayout from "@/modules/settings/pages/SettingsLayout.vue";

import MailboxesSettings from "@/modules/settings/pages/MailboxesSettings.vue";
import UsersSettings from "@/modules/settings/pages/UsersSettings.vue";
import TeamsSettings from "@/modules/settings/pages/TeamsSettings.vue";

import { SETTINGS_ROUTES } from "@/modules/settings/utils/constants/routes"; 

const settingsRoutes = [
  {
    path: "/settings/",
    name: SETTINGS_ROUTES.Settings,
    component: SettingsLayout,
    children: [
      {
        path: "mailboxes",
        name: SETTINGS_ROUTES.MailboxesSettings,
        component: MailboxesSettings,
      },
      {
        path: "teams",
        name: SETTINGS_ROUTES.TeamsSettings,
        component: TeamsSettings,
      },
      {
        path: "users",
        name: SETTINGS_ROUTES.UsersSettings,
        component: UsersSettings,
      },
    ],
  },
];

export default settingsRoutes;
