import { watch }              from "vue";
import { useQuery }           from "@tanstack/vue-query";
import clientsApi             from "@/api/clients-api";
import { storeToRefs }        from "pinia";
import parseUserInfo         from "@/modules/shared/utils/parser/userInfo";
import type { IUserInfo }     from "@/modules/shared/interfaces/UserInfo";
import { useUserInfoStore }   from "@/modules/shared/stores/userInfo";

const getUserInfo = async():Promise<IUserInfo> => {
  const { data } = await clientsApi.get("/enterprise/me/");
  return parseUserInfo(data);
};

const useUserInfo = () => {
  const store = useUserInfoStore();
  const { userInfo } = storeToRefs( store );

  const { isLoading, data } = useQuery(
    [ "userInfo"],
    getUserInfo
  );

  watch( data, userInfo => {
    if( userInfo ) store.setMailboxes(userInfo);
  }, { immediate: true });

  return {
    userInfo,
    isLoading,
  };
};

export default useUserInfo;
