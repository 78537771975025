import { ref } from "vue";
import { defineStore } from "pinia";
import type { IUserStatus } from "@/modules/shared/interfaces/UserStatus";


export const useUserStatusStore = defineStore("userStatus", () => {
  const userStatus = ref<IUserStatus>();

  return {
    // properties
    userStatus,

    // Actions
    setUserStatus( newSetUserStatus: IUserStatus ) { userStatus.value = newSetUserStatus; },
  };
});
