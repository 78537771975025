<script setup lang="ts">
import { ref }            from "vue";
import {
  Switch, SwitchDescription, SwitchGroup, SwitchLabel
}                         from "@headlessui/vue";
import { useToast }       from "vue-toastification";
import { useQueryClient } from "@tanstack/vue-query";
import InputMultiple      from "@/modules/shared/components/InputMultiple.vue";
import useInputMultiple   from "@/modules/shared/composable/useInputMultiple";
import useUserInfo        from "@/modules/shared/composable/useUserInfo";
import ButtonsModal       from "@/modules/shared/components/ButtonsModal.vue";
import useMailboxes       from "@/modules/settings/composables/useMailboxes";
import useUserStatus         from "@/modules/shared/composable/useUserStatus";

const { userInfo }        = useUserInfo();
const { userStatus }      = useUserStatus();
const queryClient         = useQueryClient();
const toast               = useToast();

const { onlyExistEmails, createMailboxes }  = useMailboxes();
const { email, emails, insert, onlyEmails } = useInputMultiple();

const enabled  = ref(false);
const focusOut = () => { email.value.length > 0 && insert(onlyExistEmails.value, userInfo.value!.allowedDomains); };

const isDisabled = () => !(email.value.length === 0 && emails.value.length > 0 && !isDisabledText() && !(createMailboxes.isLoading.value || createMailboxes.isSuccess.value));
const isDisabledText = () => (emails.value.filter((e) => e.status === false).length > 0);

const ConfirmAddMail = () => {
  const now = new Date();
  if(enabled.value){
    now.setFullYear(now.getFullYear() - 2);
  }

  if ((onlyEmails().length + onlyExistEmails.value.length) <= userStatus.value!.maxMailboxes) {
    const emailData = {
      emails: onlyEmails(),
      data_from: new Date(now).toJSON().slice(0, 10),
    };
    createMailboxes.mutate({ mailboxes: emailData, queryClient: queryClient });
    if(createMailboxes.isSuccess.value)
      toast.success("My toast content", {
        timeout: 2000
      });
  }
};
</script>

<template>
  <div>
    <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">Add new mailboxes</p>
    <div class="p-6 border-b">
      <p class="text-sm leading-5 font-normal text-gray-700 pb-8" @click="focusOut">Enter the list of mailboxes that you want to start tracking statistics for.</p>
      <div>
        <label for="email" class="pb-1 block text-sm font-medium leading-5 text-gray-700" @click="focusOut">Email address</label>
        <InputMultiple :checkMails="onlyExistEmails" :allowed_domains="userInfo!.allowedDomains" />
        <span v-show="isDisabledText()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
          Please check that all email addresses are correct.
        </span>

      </div>
      <div @click="focusOut" class="pt-8">
        <SwitchGroup as="div" class="flex items-center">
          <Switch v-model="enabled" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-400', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-offset-0']">
            <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
          </Switch>
          <span class="flex flex-grow flex-col ml-3.5">
            <SwitchLabel as="span" class="text-sm font-medium leading-5 text-gray-900" passive>Load two years of historical email data</SwitchLabel>
            <SwitchDescription as="span" class="text-sm leading-5 text-gray-500">If unselected, we’ll only start tracking email data from now on.</SwitchDescription>
          </span>
        </SwitchGroup>
      </div>
    </div>

    <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddMail" />
  </div>
</template>

<style scoped>

</style>